<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1f1f1f,black,#1f1f1f)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >

      <section class="col-lg-8 col-sm-12">

        <HeadImage :image="bioImage"
                   :title="artist.title"
                   :caption="caption"
                   class="centered"
                   align="text-left"
                   :color="secondaryColor"/>


        <section id="upcoming-events"
                 style="padding: 10px"
                 :style="{background: `linear-gradient(${this.primaryColor}, black)`}">

          <div v-if="incomingParties.length" class="text-center">
            <router-link
              class="text-left"
              :to="{ path: '/event/' + incomingParties[0].slug}"
            >
              <img
                v-lazy="this.bioImage + '?w=40w=40&fit=clamp'"
                height="40px"
                width="40px"
                class="image-circle"
                :alt="this.artist.title"
                style="margin-right: -20px; z-index: 2"
              />
              <img
                v-lazy="
              incomingParties[0].metadata.main_image.imgix_url +
              '?w=40w=40&fit=clamp'
            "
                :alt="'Upcoming Event: ' + incomingParties[0].title"
                height="40px"
                width="40px"
                class="image-circle"
              />
              Upcoming Events
              <span class="badge">({{ incomingParties.length }})</span>
            </router-link>
          </div>
        </section>

        <section class="card "
                 style="border-bottom: 1px solid black"
        >
          <h5 class="section-header">
            <i class="tim-icons icon-light-3"
               style="font-size: 12px"
               :style="{color: secondaryColor, border: `2px solid ${primaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
            LINKS</h5>
          <div class="card">
            <a v-if="artist.metadata.soundcloud" :href="artist.metadata.soundcloud" target="_blank">Soundcloud</a>
            <a v-if="artist.metadata.instagram" :href="artist.metadata.instagram" target="_blank">Instagram</a

            <p>

          </div>

          <h5 class="section-header">
            <i class="tim-icons icon-light-3"
               style="font-size: 12px"
               :style="{color: secondaryColor, border: `2px solid ${primaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
            SUMMARY</h5>
          <div class="text-left" v-html="artist.metadata.short" style="padding: 10px"></div>

        </section>


        <div
          v-if="incomingParties[0]"
          class="row text-center"
          style="padding: 10px"
          :style="{
            background: `linear-gradient(${this.secondaryColor}, black)`,
          }"
        >
          <IncomingParty :incoming-party="incomingParties[0]"
                         class="centered"
                         :logo="this.bioImage"
                         :color="this.secondaryColor"></IncomingParty>
        </div>
        <!--        <div-->
        <!--          v-if="incomingParties[0]"-->
        <!--          class="row"-->
        <!--          style="padding: 10px"-->
        <!--          :style="{-->
        <!--            background: `linear-gradient(${this.secondaryColor}, black)`,-->
        <!--          }"-->
        <!--        >-->
        <!--          <h4 class="section-header text-left"-->

        <!--          >-->
        <!--            <img-->
        <!--              v-lazy="this.bioImage + '?w=40&h=40&fit=clamp'"-->
        <!--              :alt="this.artist.title"-->

        <!--              height="40px"-->
        <!--              width="40px"-->
        <!--              style="border-radius: 300px"-->
        <!--            />-->
        <!--            Next Event </h4>-->
        <!--          <p style="margin-left: 55px;font-size: 0.7rem;margin-top: -35px;text-transform: uppercase">-->
        <!--            {{ this.nextEventDate }}</p>-->

        <!--          <div class="centered card text-center" style="padding: 20px">-->


        <!--            <h4 v-if="incomingParties[0].title" style="padding: 20px">{{ incomingParties[0].title.split("@")[0] }}</h4>-->
        <!--            <EventCard-->
        <!--              :withLineup="false"-->
        <!--              :with-title="false"-->
        <!--              v-bind:event="incomingParties[0]"-->
        <!--            >-->
        <!--            </EventCard>-->
        <!--          </div>-->
        <!--        </div>-->


        <section :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 class="card text-justify"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; min-height: 400px;">
          <h4 class="section-header">About</h4>
          <div v-html="artistContent"></div>

        </section>


        <section v-if="pastParties.length" :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; ">
          <h4 v-if="incomingParties" class="tulum-party-subtitle">PAST EVENTS</h4>
          <p style="margin-top: -20px;font-size: 11px;color:silver !important;">Tulum, Mexico</p>
          <div id="past-parties" v-if="pastParties" style="margin-top: 20px">


            <div class="text-left" v-if="pastParties.length >= 6">

              <router-link
                :key="event.slug"
                :to="{ name: 'Event', params: { slug: event.slug } }"
                v-for="event in pastParties"
              >
                <img
                  class="carrousel-image"
                  v-lazy="
                    event.metadata.main_image.imgix_url + '?w=60&h=60&fit=clamp'
                  "
                  :alt="'PAST EVENTS - ' + event.title"
                  height="60px"
                  width="60px"
                />
              </router-link>
            </div>
            <div class="text-left" v-if="pastParties.length < 6">
              <hr>

              <MiniEventCard
                v-bind:event="event"
                with-venue="true"
                v-for="(event, idx) in pastParties"
                class="card-animation-on-hover animation-transition-general animate-bounce"
                short="true"
                past="true"
                :key="event._id"
                :idx="idx"
              ></MiniEventCard>
            </div>
          </div>
        </section>
        <section :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; ">
          <h4 class="section-header" v-if="artist.metadata.spotify_id">Top Tracks</h4>

          <p
            :style="{ backgroundColor: `${this.secondaryColor}` }"
            style="margin-top: -15px;font-size: 0.7rem;color: black !important;"
            v-if="artist.metadata.spotify_listeners">Monthly Listeners:
            {{ artist.metadata.spotify_listeners.toLocaleString() }}</p>
          <br v-if="artist.metadata.spotify_listeners">
          <iframe style="border-radius:12px;" v-if="artist.metadata.spotify_id"
                  :src="`https://open.spotify.com/embed/artist/${artist.metadata.spotify_id}?utm_source=generator&theme=0`"
                  width="100%" height="200" frameBorder="0" allowfullscreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"></iframe>
        </section>

        <section v-if="artist.metadata.videoid"
                 :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; min-height: 400px;">

          <h4 class="section-header">VIDEO</h4>

          <div id="artist-video" class="card">
            <video-card :video-id="artist.metadata.videoid"
                        mtv
                        :title="artist.metadata.videotitle"
                        :caption="artist.metadata.videocaption">
            </video-card>
          </div>
        </section>
        <SmartMenu :links="links" :logo="this.djImg"
                   :with-rsvp="false"
                   :primary-color="secondaryColor">
        </SmartMenu>

      </section>
      <aside class=" col-sm-4 col-lg-4 d-none d-lg-inline text-left">

        <HotThisWeek :primary-color="primaryColor" small="true" align="text-center"></HotThisWeek>
        <DjScheduleCollection></DjScheduleCollection>
        <SmartMenu :links="links"
                   :with-rsvp="false"
                   :primary-color="primaryColor">
        </SmartMenu>
      </aside>

    </article>
  </div>
</template>

<script>
import moment from "moment";
import SmallEventCard from "@/components/EventCard/SmallEventCard";
import ImgCard from '@/components/Cards/ImgCard'
import VideoCard from '@/components/Cards/VideoCard'
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import MiniEventCard from "@/components/EventCard/MiniEventCard";
import HeadImage from "@/components/HeadImage/HeadImage";
import EventCard from "@/components/EventCard/EventCard";
import UpcomingEvents from "@/components/UpcomingEvents/UpcomingEvents";
import IncomingParty from "@/pages/Brands/IncomingParty";
import DjScheduleCollection from "@/components/Cards/DjScheduleCollection";
import HotThisWeek from "@/pages/TulumDjSchedule/HotThisWeek";

export default {
  name: 'Artist',
  props: ['slug'],
  metaInfo() {
    return {
      title: `${this.artist.title ? (this.artist.title + '. ' + this.artist.metadata.headline) : 'TULUM DJ SCHEDULE'}`,
      meta: [
        {
          name: 'description',
          content: `${this.artist.title}`,
        },
        {
          name: 'keywords',
          content: `${this.artist.title}. ${this.artist.metadata.headline}`,
        },
        {
          property: 'og:title',
          content: `${this.artist.title}`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/djs/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${this.artist.title}. ${this.artist.metadata.caption}`,
        },
        {property: 'og:type', content: 'article'},

      ],
    };
  },
  data: () => {
    return {
      djImg: "https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png",
      table: [],
      primaryColor: '#343333',
      secondaryColor: '#9d4d4d'
    };
  },
  created: function () {
    this.$store.dispatch('getArtist', {slug: this.slug});
    this.$store.dispatch('getLinks', {slug: 'tulum-nightlife'});
  },
  computed: {
    artist: {
      get() {
        return this.$store.state.artist;
      },
      links: {
        get() {
          return (
            this.$store.state.links || []
          );
        },
      },
    },
    nextEventDate: {
      get() {
        moment()
        return this.incomingParties[0] && moment(this.incomingParties[0].metadata.date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
      }
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    bioImage: {
      get() {
        return this.$store.state.artist.metadata?.main_image?.imgix_url || 'https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png';
      }
    },
    caption: {
      get() {
        return this.$store.state.artist.metadata.flag ?
          (this.$store.state.artist.metadata.flag + this.$store.state.artist.metadata.headline) : this.$store.state.artist.metadata.headline;
      }
    },
    artistContent: {
      get() {
        return '<div>' + this.$store.state.artist.content.replaceAll("<!--", "").replaceAll("-->", "") +

          '</div>'
      },
    },
    incomingParties: {
      get() {
        let parties = this.$store.state.artist.metadata.parties || [];

        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...parties]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
            moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
    pastParties: {
      get() {
        let parties = this.$store.state.artist.metadata.parties || [];

        function compareUTCTimes(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay < now;
        }

        return [...parties]
          .sort((a, b) => {
            return a.metadata.date < b.metadata.date ? 1 : -1;
          })
          .filter((x) => compareUTCTimes(x));
      },
    },
  },

  components: {
    HotThisWeek,
    DjScheduleCollection,
    IncomingParty,
    UpcomingEvents,
    MiniEventCard,
    SmallEventCard,
    ImgCard,
    VideoCard,
    SmartMenu,
    HeadImage,
    EventCard,
  },
};
</script>

